export const API_ENDPOINT =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : 'https://web-production-10f1.up.railway.app';

export type T_BITBOT_NETWORK_ID = 80001 | 250;

export const NFT_STORAGE_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweEZlOTRmMzBFRDQzMzkwQUVkMDBGQTNCY2E3NzY3Nzg2NjhiMDA3ZGQiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MTM3MDI4MjE5MSwibmFtZSI6IkVya2tpIFBhcHVuZW4ifQ.W3e0ulvqTpj3lxnph-dBi8fL5Ja3mM9pI7jzd0LwT_k';

export const BITBOT_OWNERS = [
  '0x35c6b73748272329cbf4ca0762f8765b70343914',
  '0xe6e2c266543ee7a01de3a61e8517b1a164a041ef',
  '0x7d5030ca30d4856bd858242c4aa36ccb79f87582',
];

export const MARKETPLACE_ADDRESS: {
  [networkId in T_BITBOT_NETWORK_ID]: string;
} = {
  80001: '0x4394635a3fe2fb54fdc5624ac445878f7d90a4b2',
  250: '0xd0b6c726da35c8b59cf5493cf2f25d79570fe7b8',
};
export type T_BITBOT_TOKENS = 'ETH' | 'USDC' | 'WETH' | 'PUMPKIN';
export type T_TOKEN_PRICES = {
  [networkId in T_BITBOT_TOKENS]: number;
};
export type T_TOKEN_LIST = {
  address: string;
  name: T_BITBOT_TOKENS;
  imgUrl: string;
  label: string;
  decimals: number;
}[];

export const TOKEN_LIST: {
  [networkId in T_BITBOT_NETWORK_ID]: T_TOKEN_LIST;
} = {
  80001: [
    {
      name: 'ETH',
      label: 'Matic',
      imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3890.png',
      address: '0x0000000000000000000000000000000000000000',
      decimals: 18,
    },
    {
      name: 'WETH',
      label: 'Wrapped Matic',
      imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3890.png',
      address: '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
      decimals: 18,
    },
    {
      name: 'USDC',
      label: 'USDC',
      imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3408.png',
      address: '0xe6b8a5cf854791412c1f6efc7caf629f5df1c747',
      decimals: 6,
    },
    {
      name: 'PUMPKIN',
      label: 'Pumpkin',
      imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/13004.png',
      address: '0xf76e5de63e1cd518d198ac981a6c7f21e8bbf52d',
      decimals: 18,
    },
  ],
  250: [
    {
      name: 'ETH',
      label: 'FTM',
      imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3513.png',
      address: '0x0000000000000000000000000000000000000000',
      decimals: 18,
    },
    {
      name: 'WETH',
      label: 'Wrapped FTM',
      imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3513.png',
      address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
      decimals: 18,
    },
    {
      name: 'USDC',
      label: 'USDC',
      imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/3408.png',
      address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      decimals: 6,
    },
    {
      name: 'PUMPKIN',
      label: 'Pumpkin',
      imgUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/13004.png',
      address: '0xad522217e64ec347601015797dd39050a2a69694',
      decimals: 18,
    },
  ],
};

export const MAX_UINT256 =
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
