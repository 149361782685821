import { NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useMemo } from "react";
import { useAccount } from "wagmi";
import { BITBOT_OWNERS } from "helpers/constants";

const Header = () => {
  const account = useAccount();
  const pageLinks = useMemo(() => {
    const links = [
      {
        text: "Explore",
        link: "/",
      },
      {
        text: "My Items",
        link: "/dashboard",
      },
    ];
    if (BITBOT_OWNERS.includes(account.address?.toLowerCase()))
      links.push({
        text: "Create",
        link: "/create",
      });
    return links;
  }, [account.address]);
  return (
    <div className="flex items-center justify-between border-b-2 border-b-white/10 w-full top-0 lg:px-24 px-4 z-10 transition-all duration-300 3xl:text-2xl">
      <div className="backdrop-blur-12px absolute left-0 top-0 w-full h-full z-[-1]" />
      <NavLink
        className="flex items-center text-xl md:text-2xl 3xl:text-2xl gap-2 hover:opacity-80"
        to={{
          pathname: "/",
        }}
      >
        <div className="home-logo my-2" />
        <span className="hidden lg:block ">Pumpkin Shop</span>
      </NavLink>
      <div className="hidden md:flex mx-auto gap-8">
        {pageLinks.map((item, index) => (
          <NavLink
            key={index}
            to={{
              pathname: item.link,
            }}
            className="hidden xl:flex font-medium -tracking-tight items-start rounded-lg px-3 py-6 hover:text-tezGrSt duration-150"
          >
            {item.text}
          </NavLink>
        ))}
      </div>
      <div className="flex items-center gap-4 md:gap-8">
        {/* <AiFillStar
          className="cursor-pointer text-tezGrSt hover:text-tezGrMd"
          size={20}
        />
        <IoMdCart
          className="cursor-pointer text-tezGrSt hover:text-tezGrMd"
          size={20}
          onClick={() => setCartDrawerVisible(!cartDrawerVisible)}
        /> */}

        <ConnectButton />
      </div>
    </div>
  );
};

export default Header;

/*
      const TOKEN_ADDR = "0x6f9662596efa4ab9acacc014da3eb715a09ff5d1";
      const token = Erc20__factory.connect(TOKEN_ADDR, provider.getSigner());

      const rawBalance = await token.balanceOf(account);
      const decimals = await token.decimals();

      const balance = ethers.utils.formatUnits(rawBalance, decimals);
      setTokenBalance(balance);
 */
