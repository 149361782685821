import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import '@rainbow-me/rainbowkit/styles.css';

import {
  Chain,
  darkTheme,
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { BrowserRouter } from 'react-router-dom';

const ftmChain: Chain = {
  id: 250,
  name: 'Fantom',
  network: 'fantom',
  iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png',
  iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: 'Fantom',
    symbol: 'FTM',
  },
  rpcUrls: {
    default: 'https://rpc.ankr.com/fantom	',
  },
  blockExplorers: {
    default: { name: 'FTMScan', url: 'https://ftmscan.com' },
    etherscan: { name: 'FTMScan', url: 'https://ftmscan.com' },
  },
  testnet: false,
};
// const { chains, provider } = configureChains(
//   [chain.polygonMumbai, ftmChain],
//   [
//     jsonRpcProvider({
//       rpc: (chain) => ({
//         http: `https://rpc.ankr.com/${
//           chain.id === 80001 ? "polygon_mumbai" : "fantom"
//         }`,
//       }),
//     }),
//   ]
// );

const { chains, provider } = configureChains(
  [ftmChain],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: `https://rpc.ankr.com/fantom`,
      }),
    }),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} theme={darkTheme()}>
          <App />
        </RainbowKitProvider>
      </WagmiConfig>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
