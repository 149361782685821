import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

interface IPropsCollapsableSelect {
  label: string;
  name: string;
  select: {
    icon: any;
    text: string;
    value: string;
  }[];
  forRef2?: any;
  handler: any;
}
const CollapsableSelect = (props: IPropsCollapsableSelect) => {
  const { name, select, label, forRef2, handler } = props;
  const [dropdown, setDropdown] = useState(false);
  return (
    <div className="cursor-pointer">
      <div
        className={`relative flex bg-componentBg text-bitbotComponentActive justify-between items-center border rounded-full py-2.5 px-3 duration-150 ${
          dropdown ? "border-componentFocus" : "border-componentBorder"
        }`}
        onClick={() => {
          setDropdown((dropdown) => !dropdown);
        }}
      >
        <span className="pl-2" ref={forRef2}>
          {label}
        </span>
        <FiChevronDown
          className={`mt-[3px] ml-0.5 duration-150 text-componentFocus ${
            dropdown ? "-scale-y-100" : ""
          }`}
        />
      </div>
      <div
        className={`flex flex-col px-4 overflow-hidden duration-300 ${
          dropdown ? "max-h-[500px]" : "max-h-0"
        }`}
      >
        {select.map((el, i) => (
          <label
            key={i}
            htmlFor={`radio_${name}_${i}`}
            className="cursor-pointer py-1 pl-1 pr-3 first:mt-4 mt-2 flex items-center duration-150 w-full bg-componentBg text-bitbotComponentActive border hover:border-componentFocus border-componentBorder rounded-full"
          >
            {el.icon}
            <span className="first:ml-4 ml-2 py-1">{el.text}</span>

            <input
              id={`radio_${name}_${i}`}
              type="radio"
              name={name}
              className="ml-auto w-4 h-4 text-blue-600 bg-gray-100 border-gray-300  dark:bg-gray-700 dark:border-gray-600"
              onChange={() => {
                handler && handler(el.value);
              }}
            />
          </label>
        ))}
      </div>
    </div>
  );
};
export default CollapsableSelect;
