import { useEffect, useMemo, useState } from "react";
// import { replaceIpfsProtocol } from "helpers/ipfs.utils";
// import defaultImage from "assets/images/bitbot-finance.webp";
import { TYPE_PRODUCT } from "helpers/interface";
import { fetchProductItems } from "helpers/api/products.api";
import {
  TbSortAscendingNumbers,
  TbSortAscendingLetters,
  TbSortDescendingNumbers,
  TbSortDescendingLetters,
} from "react-icons/tb";
import { GiBallPyramid } from "react-icons/gi";
import { BsStars } from "react-icons/bs";
import {
  HiOutlineSearch,
  HiOutlineThumbUp,
  HiSortAscending,
  HiSortDescending,
} from "react-icons/hi";

import CollapsableSelect from "components/UI/CollapsableSelect";
import ProductCard from "components/ProductCard";

const Explore = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [listingItems, setListingItems] = useState<TYPE_PRODUCT[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [sortValue, setSortValue] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");

  useEffect(() => {
    const fetchListingItems = async () => {
      const items: TYPE_PRODUCT[] = await fetchProductItems();
      setLoading(false);
      setListingItems(items);
    };
    if (loading === true) {
      fetchListingItems();
    }
    // eslint-disable-next-line
  }, []);

  const sortSelectProps = {
    name: "sortHandler",
    select: [
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <HiSortAscending className="text-xl" />
          </div>
        ),
        text: "Listed Time Asc",
        value: "tokenId_asc",
      },
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <HiSortDescending className="text-xl" />
          </div>
        ),
        text: "Listed Time Desc",
        value: "tokenId_desc",
      },
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <TbSortAscendingNumbers className="text-xl" />
          </div>
        ),
        text: "Price Asc",
        value: "price_asc",
      },
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <TbSortDescendingNumbers className="text-xl" />
          </div>
        ),
        text: "Price Desc",
        value: "price_desc",
      },
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <TbSortAscendingLetters className="text-xl" />
          </div>
        ),
        text: "Name Asc",
        value: "name_asc",
      },
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <TbSortDescendingLetters className="text-xl" />
          </div>
        ),
        text: "Name Desc",
        value: "name_desc",
      },
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <BsStars className="text-xl" />
          </div>
        ),
        text: "Top Sales",
        value: "owners_desc",
      },
    ],
    label: "Sort by",
    handler: setSortValue,
  };

  const statusSelectProps = {
    name: "statusHandler",
    select: [
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <GiBallPyramid className="text-xl" />
          </div>
        ),
        text: "All",
        value: "status_all",
      },
      {
        icon: (
          <div className="p-2 flex justify-center rounded-full bg-kadDarkBg">
            <HiOutlineThumbUp className="text-xl" />
          </div>
        ),
        text: "Featured",
        value: "status_featured",
      },
    ],
    label: "Status",
    handler: setFilterValue,
  };

  const filteredListingItems = useMemo(() => {
    let result = listingItems.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
    switch (sortValue) {
      case "tokenId_asc":
        result = result.sort(
          (itemA, itemB) => itemA.productId - itemB.productId
        );
        break;
      case "tokenId_desc":
        result = result.sort(
          (itemB, itemA) => itemA.productId - itemB.productId
        );
        break;
      case "price_asc":
        result = result.sort(
          (itemA, itemB) => itemA.price.WETH - itemB.price.WETH
        );
        break;
      case "price_desc":
        result = result.sort(
          (itemB, itemA) => itemA.price.WETH - itemB.price.WETH
        );
        break;
      case "name_asc":
        result = result.sort((itemA, itemB) =>
          itemA.name.localeCompare(itemB.name)
        );
        break;
      case "name_desc":
        result = result.sort((itemB, itemA) =>
          itemA.name.localeCompare(itemB.name)
        );
        break;
      case "owners_desc":
        result = result.sort(
          (itemB, itemA) => itemA.owners.length - itemB.owners.length
        );
        break;
      default:
        break;
    }

    switch (filterValue) {
      case "status_featured":
        result = result.filter((item) => item.featured);
        break;
      default:
        break;
    }
    return result;
  }, [listingItems, searchText, filterValue, sortValue]);

  return (
    <div className="flex flex-col">
      <h2 className="text-3xl 3xl:text-6xl pt-8 text-center">
        Explore NFT Items
      </h2>
      <span className="text-bitbot-gr text-xl 3xl:text-2xl text-center pb-4">
        Total: {listingItems.length} Items
      </span>
      <div className="flex items-center input w-full mx-auto px-1 py-0.5 focus-within:border-componentFocus">
        <HiOutlineSearch className="ml-2 text-2xl" />
        <input
          className="border-none flex-1 3xl:text-2xl"
          placeholder="Search products..."
          onChange={(e) => setSearchText(e.target.value)}
        />
      </div>
      <div className="flex flex-col md:flex-row gap-6 mt-8">
        <div className="w-full md:w-80 3xl:w-96 flex flex-col gap-4 3xl:text-2xl">
          <CollapsableSelect {...sortSelectProps} />
          <CollapsableSelect {...statusSelectProps} />
        </div>
        <div className="flex-1 grid md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-5 gap-4 3xl:text-2xl">
          {filteredListingItems.map((marketItem, index) => {
            return <ProductCard marketItem={marketItem} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};
export default Explore;
