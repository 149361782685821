import axios from "axios";
import { API_ENDPOINT } from "helpers/constants";
import { TYPE_PRODUCT } from "helpers/interface";

export const fetchProductItems = async (): Promise<TYPE_PRODUCT[]> => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/products`);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const fetchMyProductItems = async (
  address: string
): Promise<TYPE_PRODUCT[]> => {
  try {
    const response = await axios.get(
      `${API_ENDPOINT}/products/owner/${address.toLowerCase()}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const fetchProductItemById = async (
  productId: string
): Promise<TYPE_PRODUCT> => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/products/${productId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const fetchLastProductId = async (): Promise<number> => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/products/lastId`);
    return response.data + 1;
  } catch (error) {
    console.log(error);
    return 1;
  }
};

export const createProductItem = async (
  product: TYPE_PRODUCT
): Promise<TYPE_PRODUCT> => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/products`, product);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const checkProductSale = async (txHash: string): Promise<boolean> => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/products/verify-sale/${txHash}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const revealProductLink = async (
  productId: number,
  signatureHash: string
): Promise<string> => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/products/reveal-link/${productId}`,
      { signatureHash }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return "";
  }
};
