import { useEffect, useState } from "react";
import { TYPE_PRODUCT } from "helpers/interface";
import { fetchProductItems } from "helpers/api/products.api";
import { useAccount } from "wagmi";
import ProductCard from "components/ProductCard";

const Dashboard = () => {
  const account = useAccount();
  const [loading, setLoading] = useState<boolean>(true);
  const [listingItems, setListingItems] = useState<TYPE_PRODUCT[]>([]);
  // const [jsonArray, setJsonArray] = useState<any>([]);

  useEffect(() => {
    const fetchListingItems = async () => {
      const items: TYPE_PRODUCT[] = await fetchProductItems();
      // const _jsonArray = await Promise.all(
      //   items.map(async (item) => {
      //     try {
      //       return await (await fetch(item.tokenUri)).json();
      //     } catch {
      //       return {};
      //     }
      //   })
      // );
      // setJsonArray(_jsonArray);
      setLoading(false);
      setListingItems(items);
    };
    if (loading === true) {
      fetchListingItems();
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <div className="flex flex-col">
      <h2 className="text-3xl 3xl:text-6xl py-8 text-center">
        Explore My Items
      </h2>
      <div className="flex flex-col md:flex-row gap-6">
        <div className="flex-1 grid md:grid-cols-2 2xl:grid-cols-5 gap-4">
          {listingItems
            .filter((item) =>
              item.owners.includes(account.address?.toLowerCase())
            )
            .map((marketItem, index) => {
              return <ProductCard marketItem={marketItem} key={index} />;
            })}
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
