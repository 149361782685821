import React, { useState, useRef } from "react";
import ImageDropZone from "components/UI/ImageDropZone";
import { pinToIpfs, replaceIpfsProtocol } from "helpers/ipfs.utils";
import { useNavigate } from "react-router-dom";
import { TYPE_PRODUCT } from "helpers/interface";
import { useSigner } from "wagmi";
import {
  createProductItem,
  fetchLastProductId,
} from "helpers/api/products.api";
import { useTokenList } from "helpers/hooks/useContract";
import { T_TOKEN_PRICES } from "helpers/constants";
import { showToast } from "components/UI/ShowToast";

const CreateProduct = () => {
  const signer = useSigner();
  const navigate = useNavigate();

  const tokenList = useTokenList();

  const [imageObject, setImageObject] = useState<File | null>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const downloadLinkRef = useRef<HTMLInputElement>(null);
  const featuredRef = useRef<HTMLInputElement>(null);
  const descRef = useRef<HTMLTextAreaElement>(null);

  const [tokenPrices, setTokenPrices] = useState<T_TOKEN_PRICES>({
    ETH: 0,
    WETH: 0,
    USDC: 0,
    PUMPKIN: 0,
  });

  const onCreateNft = async () => {
    const name = nameRef.current?.value;
    const description = descRef.current?.value;
    const downloadLink = downloadLinkRef.current.value;
    if (
      (tokenPrices.ETH === 0 &&
        tokenPrices.WETH === 0 &&
        tokenPrices.USDC === 0 &&
        tokenPrices.PUMPKIN === 0) ||
      name === "" ||
      description === "" ||
      downloadLink === ""
    ) {
      showToast(
        "error",
        "There is invalid data inputed or empty field...",
        null,
        "Validation Error!"
      );
      return;
    }

    const signatureHash = await signer.data.signMessage(nameRef.current?.value);
    const json = {
      image: imageObject,
      name,
      description,
    };
    const [obj, productId] = await Promise.all([
      pinToIpfs(json),
      fetchLastProductId(),
    ]);
    const tokenUri = `https://${obj.ipnft}.ipfs.nftstorage.link/metadata.json`;

    const productItem: TYPE_PRODUCT = {
      ...json,
      imageUrl: replaceIpfsProtocol(obj.data.image.href),
      tokenUri,
      productId,
      signatureHash,
      downloadLink,
      featured: featuredRef.current.checked,
      price: tokenPrices,
    };
    await createProductItem(productItem);
    navigate("/");
  };
  return (
    <div className="flex flex-col md:flex-row items-start w-full gap-8 3xl:gap-24 pt-16 3xl:text-2xl">
      <div className="p-6 rounded-lg bg-componentBg">
        <span>Live Preview</span>
        <div className="mt-2 w-60 md:w-80 h-60 md:h-80 3xl:w-[40rem] 3xl:h-[40rem] bg-[#282837]">
          <ImageDropZone
            imageObject={imageObject}
            setImageObject={setImageObject}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col gap-4 w-full">
        <h2 className="text-3xl 3xl:text-6xl">Create Product Item</h2>
        <span>
          Enter Details<span className="text-bitbot-gr"> (*) REQUIRED </span>
        </span>
        <div className="w-full flex flex-col">
          <span className="text-sm 3xl:text-xl text-bitbotComponentActive font-normal uppercase mb-2">
            Product Name <span className="text-bitbot-gr">*</span>
          </span>
          <div>
            <input
              className="smooth-input w-full"
              placeholder="E.G. `Resident Evil 3 Deluxe Edition 2020`"
              ref={nameRef}
            />
          </div>
        </div>
        <div className="w-full flex flex-col">
          <span className="text-sm 3xl:text-xl text-bitbotComponentActive font-normal uppercase mb-2">
            Product Description <span className="text-bitbot-gr">*</span>
          </span>
          <div>
            <textarea
              className="smooth-input w-full"
              placeholder="E.G. `Resident Evil 3 Deluxe Edition 2020`"
              rows={5}
              ref={descRef}
            />
          </div>
        </div>

        {tokenList?.map((token, index) => {
          return (
            <div key={index} className="flex flex-col">
              <span className="flex items-center text-sm 3xl:text-xl text-bitbotComponentActive font-normal uppercase mb-2">
                Price ({token.label}
                <img
                  className="mx-1 w-6 rounded-full"
                  src={token.imgUrl}
                  alt="token logo"
                />
                ) <span className="text-bitbot-gr">*</span>
              </span>
              <div>
                <input
                  className="smooth-input w-full"
                  placeholder="E.G. `0.99 or 399.99`"
                  onChange={(e) => {
                    setTokenPrices((_tokenPrices) => {
                      return {
                        ..._tokenPrices,
                        [token.name]: parseFloat(e.target.value || "0"),
                      };
                    });
                  }}
                />
              </div>
            </div>
          );
        })}
        <div className="w-full flex flex-col">
          <span className="text-sm 3xl:text-xl text-bitbotComponentActive font-normal uppercase mb-2">
            Download Link <span className="text-bitbot-gr">*</span>
          </span>
          <div>
            <input
              className="smooth-input w-full"
              placeholder="E.G. `https://store.steampowered.com/app/952060/Resident_Evil_3`"
              ref={downloadLinkRef}
            />
          </div>
        </div>

        <div className="flex items-center gap-4">
          <label htmlFor="featured_checkbox">Featured Item</label>
          <input id="featured_checkbox" type="checkbox" ref={featuredRef} />
          <button className="ml-auto w-64 bitbot-button" onClick={onCreateNft}>
            Create
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateProduct;
