import { Erc20__factory, Marketplace__factory } from "contracts/types";
import {
  MARKETPLACE_ADDRESS,
  TOKEN_LIST,
  T_TOKEN_LIST,
} from "helpers/constants";
import { useNetwork, useSigner } from "wagmi";

const useChainId = () => {
  const network = useNetwork();
  return network.chain?.id || 80001;
};

const useERC20Contract = (address: string) => {
  const { data: signer } = useSigner();
  // @ts-ignore
  return Erc20__factory.connect(address, signer).deployed();
};

const useMarketPlaceContract = () => {
  const { data: signer } = useSigner();
  const chainId = useChainId();
  // @ts-ignore
  return Marketplace__factory.connect(MARKETPLACE_ADDRESS[chainId], signer);
};

const useTokenList = (): T_TOKEN_LIST => {
  const chainId = useChainId();
  return TOKEN_LIST[chainId] || [];
};

export { useERC20Contract, useMarketPlaceContract, useTokenList };
