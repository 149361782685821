import toast, { ToastPosition } from "react-hot-toast";

import { ReactComponent as BounceFail } from "assets/images/BounceFail.svg";
import { ReactComponent as BounceInfo } from "assets/images/BounceInfo.svg";

type TYPE_TOAST = "error" | "info" | "success";
export const notify = (
  type: TYPE_TOAST,
  icon: any,
  title: string,
  text: string,
  position: ToastPosition = "bottom-right"
) =>
  toast.custom(
    (t) => (
      <div
        onClick={() => toast.dismiss(t.id)}
        className={`${
          t.visible ? "animation-enter" : "animation-leave"
        } toast-wrapper toast-${type}`}
      >
        <div className="flex-1 w-0 p-2">
          <div className="flex items-center">
            <div className="flex-shrink-0 pt-0.5 m-auto">{icon}</div>
            <div className="ml-3 flex-1">
              <p className="size-1 toast-title">{title}</p>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    ),
    { position }
  );

export const walletConnectNotify = () => {
  notify(
    "error",
    <BounceFail className="w-16 h-16" />,
    "Wallet Connet",
    "Please connect MetaMask or any other wallet"
  );
};

export const notifyEvent = (type, icon, title, text) => {
  notify(type, icon, title, text, "bottom-right");
};

export const showToast = (
  type: TYPE_TOAST = "success",
  text: string = "",
  icon: any,
  title: string = ""
) => {
  if (type.toLocaleLowerCase() === "error")
    icon = <BounceFail className="w-16 h-16" />;
  if (type.toLocaleLowerCase() === "info")
    icon = <BounceInfo className="w-16 h-16" />;
  notify(type, icon, title, text);
};
