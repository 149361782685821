import { useTokenList } from "helpers/hooks/useContract";
import { TYPE_PRODUCT } from "helpers/interface";
import { FcBookmark } from "react-icons/fc";
import { NavLink } from "react-router-dom";

const ProductCard = ({ marketItem }: { marketItem: TYPE_PRODUCT }) => {
  const tokenList = useTokenList();
  return (
    <NavLink
      to={`/nft/${marketItem.productId}`}
      className={`flex flex-col shadow-lg rounded-lg overflow-hidden m-2 p-2 cursor-pointer gap-2 bg-componentBg ${
        marketItem.featured ? "featured-product" : ""
      }`}
    >
      <div className="overflow-hidden rounded-lg aspect-square">
        <img
          src={marketItem.imageUrl}
          className="duration-300 hover:scale-110 w-full"
          alt="nft avatar"
        />
      </div>
      <span className="text-xl flex uppercase items-center">
        {marketItem.featured ? <FcBookmark /> : <span className="ml-2"> </span>}
        {marketItem.name}
      </span>
      <div className="grid grid-cols-2 gap-1">
        {tokenList
          .filter((token) => marketItem.price[token.name] !== 0)
          .map((token, index) => {
            return (
              <div key={index} className="flex flex-1">
                <img
                  className="mx-1 w-6 h-6 3xl:w-8 3xl:h-8 rounded-full"
                  src={token.imgUrl}
                  alt="token logo"
                />
                {marketItem.price[token.name]}
              </div>
            );
          })}
      </div>
    </NavLink>
  );
};

export default ProductCard;
