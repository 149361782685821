import "./App.css";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Explore from "pages/Explore";
import NftDetails from "pages/NftDetails";
import Dashboard from "pages/Dashboard";
import CreateProduct from "pages/CreateProduct";
import { Toaster } from "react-hot-toast";
function App() {
  return (
    <>
      <Header />

      <div className="main-container">
        <Toaster />
        <Routes>
          <Route path="/" element={<Explore />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create" element={<CreateProduct />} />
          <Route path="/nft/:tokenId" element={<NftDetails />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
