import {
  useMarketPlaceContract,
  useTokenList,
} from "helpers/hooks/useContract";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { replaceIpfsProtocol } from "helpers/ipfs.utils";
import defaultImage from "assets/images/bitbot-finance.png";
import { BigNumber, ContractTransaction, utils } from "ethers";
import {
  checkProductSale,
  fetchProductItemById,
  revealProductLink,
} from "helpers/api/products.api";
import { TYPE_PRODUCT } from "helpers/interface";
import { useAccount, useSigner } from "wagmi";
import { showToast, walletConnectNotify } from "components/UI/ShowToast";
import { Erc20__factory } from "contracts/types";
import { MAX_UINT256 } from "helpers/constants";
import { beautifyAddress } from "helpers/string.utils";
// import { T_BITBOT_TOKENS } from "helpers/constants";
import { FcBookmark } from "react-icons/fc";

const NftDetails = () => {
  const signer = useSigner();
  const account = useAccount();
  const tokenList = useTokenList();
  const marketPlaceContract = useMarketPlaceContract();
  const { tokenId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [marketItem, setMarketItem] = useState<TYPE_PRODUCT>();
  const [paymentTokenId, setPaymentTokenId] = useState<number>(0);
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0));

  const fetchItem = async () => {
    const _marketItem = await fetchProductItemById(tokenId);
    setMarketItem(_marketItem);
    setLoading(false);
  };

  useEffect(() => {
    if (loading === true) fetchItem();
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (marketItem && parseInt(tokenList[paymentTokenId].address) !== 0) {
      const paymentERC20 = Erc20__factory.connect(
        tokenList[paymentTokenId].address,
        signer.data
      );
      paymentERC20
        .allowance(account.address, marketPlaceContract.address)
        .then((_allowance) => setAllowance(_allowance));
    }
    // eslint-disable-next-line
  }, [paymentTokenId, marketItem]);

  const productPrice: BigNumber = useMemo(() => {
    const price = marketItem?.price[tokenList[paymentTokenId].name] || 0;
    return BigNumber.from(
      BigInt(
        Math.ceil(10 ** tokenList[paymentTokenId].decimals * price)
      ).toString()
    );
  }, [paymentTokenId, tokenList, marketItem]);

  const myItem: boolean = useMemo(
    () =>
      account?.address?.length &&
      marketItem?.owners.includes(account.address.toLowerCase()),
    [marketItem, account]
  );

  const approveToken = async () => {
    const paymentERC20 = Erc20__factory.connect(
      tokenList[paymentTokenId].address,
      signer.data
    );
    showToast(
      "info",
      `Approve our marketplace contract to spend your ${tokenList[paymentTokenId].label}`,
      null,
      "🤘 Waiting for approval"
    );
    // need to approve wallet
    const approvalTx = await paymentERC20.approve(
      marketPlaceContract.address,
      MAX_UINT256
    );
    await approvalTx.wait(2);
    fetchItem();
  };

  const onMintNft = async () => {
    if (!account.address) {
      walletConnectNotify();
      return;
    }

    let tx: ContractTransaction;
    if (paymentTokenId === 0) {
      tx = await marketPlaceContract.createToken(
        marketItem.tokenUri,
        marketItem.productId,
        paymentTokenId,
        utils.parseEther(
          marketItem.price[tokenList[paymentTokenId].name].toString()
        ),
        {
          value: utils.parseEther(
            marketItem.price[tokenList[paymentTokenId].name].toString()
          ),
        }
      );
    } else {
      const paymentERC20 = Erc20__factory.connect(
        tokenList[paymentTokenId].address,
        signer.data
      );
      const _allowance = await paymentERC20.allowance(
        account.address,
        marketPlaceContract.address
      );
      setAllowance(_allowance);
      if (_allowance.lt(productPrice)) {
        await approveToken();
      }
      console.log(
        marketItem.tokenUri,
        marketItem.productId,
        paymentTokenId,
        productPrice
      );

      tx = await marketPlaceContract.createToken(
        marketItem.tokenUri,
        marketItem.productId,
        paymentTokenId,
        productPrice
      );
    }

    showToast(
      "info",
      "Wait until blockchain confirmation...",
      null,
      "Tx was Submitted!"
    );
    const result = await tx.wait(2);
    const txHash = result.transactionHash;

    showToast(
      "success",
      `Sign message to claim download link for ${marketItem.name}`,
      null,
      "Mint was confirmed!"
    );
    await checkProductSale(txHash);
    fetchItem();
  };

  const claimDownLink = async (productId: number, openToNew = true) => {
    const signatureHash = await signer.data.signMessage(productId.toString());
    const link = await revealProductLink(productId, signatureHash);
    if (link) {
      if (openToNew) window.open(link, "_blank").focus();
      else {
        navigator.clipboard.writeText(link);
        showToast(
          "info",
          null,
          "Hwooa!!",
          "Download Link copied to clipboard!"
        );
      }
    }
  };

  return loading === false ? (
    <div className="flex flex-col md:flex-row gap-x-16 gap-y-4 pt-16 items-start">
      <div className="overflow-hidden rounded-lg 3xl:rounded-2xl flex-1 bg-componentBorder">
        <img
          src={replaceIpfsProtocol(marketItem.imageUrl) || defaultImage}
          className="duration-300 hover:scale-110 w-full"
          alt="nft avatar"
        />
      </div>
      <div className="flex-1 flex flex-col py-8 gap-4 md:text-2xl">
        <div className="text-3xl md:text-6xl flex flex-wrap items-start text-bitbot-gr">
          {marketItem.featured && <FcBookmark />}
          {marketItem.name}
        </div>

        <span className="text-lg lg:text-2xl opacity-60">Description</span>
        <p>{marketItem.description}</p>
        <span className="text-lg lg:text-2xl opacity-60">Information</span>
        <div className="flex flex-col gap-4 rounded-lg 3xl:rounded-2xl bg-componentBg py-4 px-6">
          <div className="flex justify-between">
            <span>Product ID</span>
            <span className="opacity-60">{marketItem.productId}</span>
          </div>
          <div className="flex justify-between">
            <span>Contract address</span>
            <span className="opacity-60">
              {beautifyAddress(marketPlaceContract.address)}
            </span>
          </div>
          <div className="flex justify-between">
            <span>Token Standard</span>
            <span className="opacity-60">ERC721</span>
          </div>
          <div className="flex justify-between">
            <span>Blockchain</span>
            <span className="opacity-60">Fantom</span>
          </div>
        </div>
        <div className="h-1 bg-componentBg" />
        {!myItem && (
          <>
            <span>Choose Payment Method</span>
            <div className="flex flex-col gap-1">
              {tokenList.map((token, index) => {
                return (
                  marketItem.price[token.name] > 0 && (
                    <label
                      key={index}
                      className="flex items-center flex-1 cursor-pointer"
                    >
                      <input
                        type="radio"
                        value={token.name}
                        name="payment_token"
                        defaultChecked={
                          tokenList[paymentTokenId].name === token.name
                        }
                        onClick={() => setPaymentTokenId(index)}
                      />
                      <img
                        className="mx-1 w-8 h-8 rounded-full"
                        src={token.imgUrl}
                        alt="token logo"
                      />
                      {marketItem.price[token.name]} {token.label}
                    </label>
                  )
                );
              })}
            </div>
          </>
        )}

        {!myItem &&
          (paymentTokenId !== 0 && allowance.lt(productPrice) ? (
            <button
              className="bitbot-button md:w-48 text-xl md:text-2xl"
              onClick={approveToken}
            >
              Approve
            </button>
          ) : (
            <button
              className="bitbot-button md:w-48 text-xl md:text-2xl"
              onClick={onMintNft}
            >
              Mint to Buy
            </button>
          ))}
        {myItem && (
          <button
            className="bitbot-button md:w-48 text-xl md:text-2xl"
            onClick={() => {
              claimDownLink(marketItem.productId);
            }}
          >
            Download
          </button>
        )}
        {myItem && (
          <button
            className="bitbot-button md:w-48 text-xl md:text-2xl"
            onClick={() => claimDownLink(marketItem.productId, false)}
          >
            Copy Link
          </button>
        )}
      </div>
    </div>
  ) : (
    <div>Loading</div>
  );
};
export default NftDetails;
